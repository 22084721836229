"use client";

import { useEffect, useState } from "react";

import { LoaderCircle } from "lucide-react";
import Image from "next/image";
import type { BuiltInProviderType } from "next-auth/providers/index";
import { signIn } from "next-auth/react";
import { useTranslations } from "next-intl";
import { useTheme } from "next-themes";

import { Button } from "@components/common/button";
import { ToastAction } from "@components/common/toast";
import Tooltip, { TooltipContent, TooltipTrigger } from "@components/tooltip";
import { useToast } from "@hooks/use-toast";
import GoogleDarkIcon from "@public/images/brands/dark/google.svg";
import LinkedinDarkIcon from "@public/images/brands/dark/linkedin.svg";
import XDarkIcon from "@public/images/brands/dark/x.svg";
import GoogleLightIcon from "@public/images/brands/light/google.svg";
import LinkedinLightIcon from "@public/images/brands/light/linkedin.svg";
import XLightIcon from "@public/images/brands/light/x.svg";

const SignInThirdParty = ({ isMobile }: { isMobile: boolean }): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDarkIcon, setIsDarkIcon] = useState(false);
  const t = useTranslations("authentication.signThirdParty");
  const tToast = useTranslations("common.toast");
  const { theme } = useTheme();
  const { toast } = useToast();

  useEffect(() => {
    setIsDarkIcon(theme === "dark");
  }, [theme]);

  const signInHandler = (provider: BuiltInProviderType): void => {
    setIsLoading(true);
    signIn(provider).catch(() => {
      toast({
        title: tToast("errorTitle"),
        description: tToast("errorDescription"),
        variant: "destructive",
        action: (
          <ToastAction
            altText={tToast("tryAgain")}
            onClick={() => signInHandler(provider)}
          >
            {tToast("tryAgain")}
          </ToastAction>
        ),
      });
      setIsLoading(false);
    });
  };

  return (
    <div className="flex justify-center space-x-4">
      <Tooltip isMobile={isMobile}>
        <TooltipTrigger asChild>
          <Button
            variant={"outline"}
            size={"icon"}
            className="h-12 w-12 bg-transparent"
            disabled={isLoading}
            onClick={() => signInHandler("google")}
          >
            {isLoading && (
              <LoaderCircle width={24} height={24} className="animate-spin" />
            )}
            {!isLoading && (
              <Image
                src={isDarkIcon ? GoogleDarkIcon : GoogleLightIcon}
                alt={t("googleLogo")}
                width={24}
                height={24}
              />
            )}
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>{t("googleSignIn")}</p>
        </TooltipContent>
      </Tooltip>
      <Tooltip isMobile={isMobile}>
        <TooltipTrigger asChild>
          <Button
            variant={"outline"}
            size={"icon"}
            className="h-12 w-12 bg-transparent"
            disabled={isLoading}
            onClick={() => signInHandler("linkedin")}
          >
            {isLoading && (
              <LoaderCircle width={24} height={24} className="animate-spin" />
            )}
            {!isLoading && (
              <Image
                src={isDarkIcon ? LinkedinDarkIcon : LinkedinLightIcon}
                alt={t("linkedinLogo")}
                width={24}
                height={24}
              />
            )}
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>{t("linkedinSignIn")}</p>
        </TooltipContent>
      </Tooltip>
      <Tooltip isMobile={isMobile}>
        <TooltipTrigger asChild>
          <Button
            variant={"outline"}
            size={"icon"}
            className="h-12 w-12 bg-transparent"
            disabled={isLoading}
            onClick={() => signInHandler("twitter")}
          >
            {isLoading && (
              <LoaderCircle width={24} height={24} className="animate-spin" />
            )}
            {!isLoading && (
              <Image
                src={isDarkIcon ? XDarkIcon : XLightIcon}
                alt={t("xLogo")}
                width={24}
                height={24}
              />
            )}
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>{t("xSignIn")}</p>
        </TooltipContent>
      </Tooltip>
    </div>
  );
};

export default SignInThirdParty;
